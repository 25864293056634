import { ImageStyle, TextStyle } from 'react-native';
import { useWindowDimensions, ViewStyle } from 'react-native';

type TcondView<T> = T extends 'view' ? ViewStyle[] | ViewStyle : undefined;
type TcondTxt<T> = T extends 'txt' ? TextStyle[] | TextStyle : undefined;
type TcondImg<T> = T extends 'img' ? ImageStyle[] | ImageStyle : undefined;

type Tconds<T> = TcondView<T> | TcondTxt<T> | TcondImg<T>;
type TcondProp<T> = { mob: Tconds<T>; desk: Tconds<T> };

export type TuseResizeProp<T> = TcondProp<T>;
type Tprop<T> = TuseResizeProp<T>;

export default function useResize() {
  // ----------- set Hooks
  const condDesk = useWindowDimensions().width >= 600;

  // ----------- set Objct Functions to return
  const objReturn = {
    resizeView: (stl: Tprop<'view'>) => baseFunc(stl, condDesk),
    resizeTxt: (stl: Tprop<'txt'>) => baseFunc(stl, condDesk),
    resizeImg: (stl: Tprop<'img'>) => baseFunc(stl, condDesk),
  };

  // ----------- set Return
  return objReturn;
}

// ----------- set BaseFunction
function baseFunc<T>(stl: Tprop<T>, condDesk: boolean): Tconds<T>;
function baseFunc(stl: any, condDesk: any) {
  // ----------- set Return
  const { desk, mob } = stl;
  const condStl = condDesk ? desk : mob;

  return condStl;
}
