// ----------- import Packs
import React from 'react';
import { TextInput, View, Text, ViewStyle } from 'react-native';

// ----------- import Internals
import { maskSelector } from './mask';
import { setStyle } from './setStyle';
import { TinfoData } from './types';
import { setChanges } from './setChanges';
import { primaryColor } from '#stl';
import { ezLog } from '#utils';

// ----------- set Local Types
type Tprops = { getData: Function; infoData: TinfoData };
type TComp = React.FC<Tprops>;

// ----------- export Component
export const IptTxt: TComp = ({ getData, infoData }) => {
  // ---------- set Params
  const {
    field,
    label,
    pHolder,
    lines,
    condValue,
    condNumber,
    editData,
    maskType,
    infoStl = { stlLabel: { fontSize: 14 }, colorBase: primaryColor },
  } = infoData;
  const { sets, vars } = setChanges(getData, field, maskType, condValue);
  const { changeValue, changeBlur, changeFocus } = sets;
  const { sttValue, setValue, sttFocus } = vars;

  // ---------- set Edit Data
  let valueField = sttValue ?? editData;

  const fxUpdt = () => {
    const condEdit = editData ?? '';
    setValue(condEdit);
  };
  React.useEffect(fxUpdt, [editData]);

  // ---------- set Conditionals
  const condValToShow = maskType
    ? maskSelector(maskType, valueField)
    : valueField;

  const condLines = lines && lines > 1;

  // ---------- set Style
  const stl = setStyle({ vars, infoStl, condLines });

  // ---------- set Conditionals
  const condPholder = stl.sttLabelUp ? pHolder : label;

  // ---------- set Return
  const iptProps = {
    style: { ...stl.ipt },
    onChangeText: changeValue,
    onBlur: changeBlur,
    onFocus: changeFocus,
    value: condValToShow,
    placeholderTextColor: stl.condPhTxtColor,
    placeholder: condPholder,
    multiline: condLines,
    numberOfLines: lines,
    // keyboardType: condNumber && 'numeric',
  };

  return (
    <View style={stl.container}>
      <View style={stl.labelView}>
        <Text style={stl.stlLabel}>{stl.condLabelUp && label}</Text>
      </View>

      <TextInput {...iptProps} />
    </View>
  );
};
