// ---------- import Packs
import React from 'react';

// ---------- import Internals
import { A1_View } from './Views';

// ---------- set Local Types
type Tprops = {};
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A1: Tcomp = () => {
  // ---------- set Routes

  // ---------- set Return
  const infoToView = {};
  return <A1_View info={infoToView} />;
};
