import km from '#imgs/mrkumo.png';
import km1 from '#imgs/mrkumo1.png';
import km2 from '#imgs/mrkumo2.png';

import em from '#imgs/emed.png';

import nc1 from '#imgs/noisecall1.png';
import nc2 from '#imgs/noisecall2.png';

import bk1 from '#imgs/bikelete.png';
import bk2 from '#imgs/bikelete1.png';

import zk1 from '#imgs/zeduk1.png';
import zk2 from '#imgs/zeduk2.png';
import zk3 from '#imgs/zeduk3.png';

export const works = {
  mrkumo: {
    title: 'Mr. Kumo',
    content: 'Projeto de Identidade Visual, Vídeo Promocional e Website.',
    img: km,
    img1: km1,
    img2: km2,
  },
  bikelete: {
    title: 'Bikelete',
    content: 'Projeto de Identidade Visual e Website.',
    img: bk1,
    img1: bk2,
  },
  emed: {
    title: 'Emed Arquitetura Hospitalar',
    content: 'Projeto de Website.',
    img: em,
  },
  zeduk: {
    title: 'Zeduk App.',
    content: 'Projeto de Identidade Visual, Aplicativo e Website.',
    img: zk1,
    img1: zk2,
    img2: zk3,
  },
  noisecall: {
    title: 'Noisecall',
    content: 'Projeto de Identidade Visual, Aplicativo e Website.',
    img: nc1,
    img1: nc2,
  },
};
