// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={20}
      height={18}
      viewBox="0 0 8 10"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M0 4.491V2.277C.004 1.161.739.255 1.818.04c.112-.023.227-.034.341-.035C2.768 0 3.38-.001 3.99.002a.446.446 0 0 1 .42.614c-.069.177-.226.28-.436.281H2.236c-.633.004-1.16.42-1.307 1.032a1.506 1.506 0 0 0-.036.345C.89 3.757.89 5.24.893 6.723c0 .784.58 1.37 1.356 1.374.584.004 1.166 0 1.75.002.187 0 .326.09.403.263a.432.432 0 0 1-.06.466.39.39 0 0 1-.312.164c-.67 0-1.34.024-2.009-.01C1.003 8.927.126 8.048.02 7.02a3.53 3.53 0 0 1-.018-.347V4.49Z"
        fill="#000"
      />
      <Path
        d="M6.476 4.946H3.15c-.252 0-.422-.13-.472-.357a.451.451 0 0 1 .21-.478.438.438 0 0 1 .175-.058c.045-.004.09-.003.135-.003h3.254l.035-.047c-.034-.017-.075-.028-.1-.053-.309-.309-.616-.62-.92-.931a.43.43 0 0 1-.11-.466.425.425 0 0 1 .369-.3.4.4 0 0 1 .354.118c.6.605 1.198 1.212 1.795 1.82a.43.43 0 0 1 .093.478.431.431 0 0 1-.096.14c-.594.607-1.19 1.211-1.789 1.812a.44.44 0 0 1-.626-.007.45.45 0 0 1 .007-.633c.304-.314.612-.622.92-.933l.093-.102Z"
        fill="#000"
      />
    </Svg>
  );
}

export default SvgComponent;
