// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOWimport * as React from "react";
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={1706.667}
      height={1373.333}
      viewBox="0 0 1280 1030"
      fill="red"
      {...props}
    >
      <Path d="M35.1 1.6C18.3 6.1 5.9 18.7 1.5 35.7.2 40.7 0 88.4 0 426.5c0 342.7.2 385.7 1.5 391 4 15.3 14.6 26.8 30.3 32.8l5.7 2.2 228.8.3 228.7.2v143h-25.9c-14.2 0-27.3.3-29.1.6-4.7 1-10.5 7-12 12.4-2.1 7.7 2.3 16.6 9.7 19.6 4.8 2.1 399.8 2.1 404.6 0 7.3-3 11.7-12.1 9.6-19.9-1.3-5.1-7.3-11.1-11.9-12.1-1.8-.3-14.9-.6-29.1-.6H785V853l228.8-.2 228.7-.3 5.7-2.2c15.7-6 26.3-17.5 30.3-32.8 1.3-5.3 1.5-48.3 1.5-391 0-342.6-.2-385.7-1.5-391-4.5-17.1-17-29.5-34.2-34-5-1.3-76.8-1.5-604.8-1.4C119.7.1 39.8.3 35.1 1.6zm1185.7 422.1-.3 365.8H59.5l-.3-365.8L59 58h1162l-.2 365.7z" />
    </Svg>
  );
}

export default SvgComponent;
