// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg fill="red" {...props}>
      <Path d="M19.95 15.6a1.35 1.35 0 11-2.7 0 1.35 1.35 0 012.7 0z" />
      <Path d="M20.55 8.4h-3V3c0-.6-.3-1.2-.9-1.65-.6-.3-1.2-.3-1.8-.15l-12 6.45-.3.3c-.9.6-1.5 1.5-1.5 2.55v10.05c0 1.2 1.05 2.25 2.25 2.25h17.25c1.2 0 2.25-1.05 2.25-2.25v-9.9c.15-1.35-.9-2.25-2.25-2.25zm-5.1-5.1l.15.9v4.05H6.3l9.15-4.95zm5.4 17.25c0 .15-.15.15-.15.15H3.45c-.15 0-.15-.15-.15-.15v-9.9c0-.15.15-.15.15-.15H20.7c.15 0 .15.15.15.15v9.9z" />
    </Svg>
  );
}

export default SvgComponent;
