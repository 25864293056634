// ----------- import packs
import { Troutes, TscRoute } from '@morfos/routes';

// ----------- import internals
import { routes as pub } from '../../screens/pub';

// ----------- set Function
export const setRoutes = (): Troutes => {
  // ----------- set Array with Routes
  const routes: Troutes = {};
  const mapRoutes = (item: { route: TscRoute }) => {
    const routeData = item.route;
    const path = routeData.path;
    routes[path] = routeData;
  };

  // ----------- set Data
  const arrPub = Object.values(pub);

  // ----------- set Routes to Send
  arrPub.forEach(mapRoutes);

  // ----------- set Return
  return routes;
};
