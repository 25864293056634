import React from 'react';
import { Image, Text, View, StyleSheet } from 'react-native';
import { useStl, useResize } from '#tempUseMorfos';
import techs from '#imgs/tecnologias.png';
import morfos from '#imgs/morfos-logo.png';
import face from '#imgs/face.png';
import insta from '#imgs/insta.png';
import twitter from '#imgs/twitter.png';
import { setData } from '#ctData';

export const Footer = () => {
  const { resizeTxt, resizeImg, resizeView } = useResize();

  // Responsive Style
  const container = resizeView({
    mob: {
      width: '100%',
      ...useStl.flexBetween,
      backgroundColor: '#242424',
      paddingVertical: 20,
      paddingHorizontal: 20,
    },
    desk: {
      ...useStl.flexRow,
      ...useStl.flexBetween,
      backgroundColor: '#242424',
      paddingVertical: 35,
      paddingHorizontal: 150,
    },
  });

  const social = resizeView({
    mob: { width: '80%' },
    desk: { width: '20%' },
  });

  const socialIcons = resizeView({
    mob: {
      ...useStl.flexRow,
      alignSelf: 'flex-start',
      marginBottom: 20,
    },
    desk: { ...useStl.flexRow, alignSelf: 'center' },
  });

  const about = resizeView({
    mob: { width: '80%' },
    desk: { marginBottom: 15, width: '20%', alignSelf: 'baseline' },
  });

  const services = resizeView({
    mob: { width: '80%', marginTop: 20, alignItems: 'flex-start' },
    desk: { width: '20%', alignSelf: 'baseline', alignItems: 'flex-start' },
  });

  const stlTechs = resizeView({
    mob: { width: '80%', marginTop: 20 },
    desk: { width: '20%', alignSelf: 'baseline' },
  });

  const title = resizeTxt({
    mob: {
      color: '#fff',
      fontSize: 14,
      // // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
    },
    desk: {
      color: '#fff',
      fontSize: 14,
      // // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
    },
  });

  const paragraph = resizeTxt({
    mob: {
      color: '#a5a4a2',
      fontSize: 14,
      marginTop: 15,
      fontWeight: '400',
      // // fontFamily: 'Open Sans',
      lineHeight: 15,
      alignSelf: 'flex-start',
    },
    desk: {
      color: '#a5a4a2',
      fontSize: 14,
      marginTop: 15,
      fontWeight: '400',
      // // fontFamily: 'Open Sans',
      lineHeight: 15,
      alignSelf: 'flex-start',
    },
  });

  const imgMorfos = resizeImg({
    mob: {
      alignItems: 'flex-start',
      width: 165,
      height: 40,
      marginVertical: 26,
    },
    desk: { alignSelf: 'center', width: 165, height: 40 },
  });

  const imgIcons = resizeImg({
    mob: {
      width: 17,
      height: 17,
      marginTop: 10,
      marginRight: 10,
      marginBottom: 10,
      alignSelf: 'flex-start',
    },
    desk: {
      width: 17,
      height: 17,
      marginTop: 10,
      marginRight: 10,
      marginBottom: 150,
    },
  });

  const imgTechs = resizeImg({
    mob: {
      width: '100%',
      resizeMode: 'contain',
      height: 60,
      alignSelf: 'center',
      marginTop: 5,
      marginBottom: 25,
    },
    desk: { width: '100%', resizeMode: 'contain', height: 61, marginTop: 15 },
  });

  // End Responsive Style

  const fnGetLayout = e =>
    setData({ path: 'pub.A0.layout.5', val: e.nativeEvent.layout });

  return (
    <View style={container} onLayout={fnGetLayout}>
      <View style={social}>
        <Image source={morfos} style={imgMorfos} />
        <Text style={paragraph}>Morfos Developers Guarulhos, SP</Text>
        <View style={socialIcons}>
          <Image source={face} style={imgIcons} />
          <Image source={insta} style={imgIcons} />
          <Image source={twitter} style={imgIcons} />
        </View>
      </View>
      <View style={about}>
        <Text style={title}>Sobre</Text>
        <Text style={paragraph}>
          Cada projeto é um projeto, com necessidades e complexidades
          diferentes. Nós analisamos sempre seu projeto antes de começar o
          trabalho pois queremos entregar um código adequado e customizado para
          cada um desses projetos. Trabalhamos dentro dos padrões W3C,
          produzimos um código semântico, com layout responsivo e acessível
          sempre com as melhores e mais modernas tecnologias.
        </Text>
      </View>
      <View style={services}>
        <Text style={title}>Serviços</Text>
        <Text style={paragraph}>- Webdesign</Text>
        <Text style={paragraph}>- Aplicativos Nativos</Text>
        <Text style={paragraph}>- Codificação nos Padrões de SEO</Text>
        <Text style={paragraph}>- Desenvolvimento Responsivo</Text>
        <Text style={paragraph}>- Desenvolvimento Acessível</Text>
      </View>
      <View style={stlTechs}>
        <Text style={title}>Tecnologias</Text>
        <Image source={techs} style={imgTechs} />
      </View>
    </View>
  );
};
