// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { primaryColor } from '#stl';

// ----------- set Local Types
type Tprops = {
  vars: {
    sttValue: string;
    sttLabelUp: boolean;
    sttFocus: boolean;
  };
  infoStl?: any;
  // infoStl?: TinfoStl;
  condLines?: number | boolean;
};

// ----------- export function
export function setStyle(props: Tprops) {
  // ---------- set Props
  const { infoStl, condLines, vars } = props;
  const { sttLabelUp, sttFocus, sttValue } = vars;

  // ---------- set Styles
  let colorBase: string;
  let myStlLabel: { fontSize?: number };
  let labelFontSize: number;
  let iptLabelDown: number;
  const condLabelUp = sttLabelUp || sttValue;
  const setLabelSize = (num: number) => (!condLabelUp ? num : num * 0.8);

  if (infoStl) {
    colorBase = infoStl.colorBase;
    myStlLabel = infoStl.stlLabel;
    iptLabelDown = infoStl.stlLabel ? infoStl.stlLabel.fontSize : 15;
  } else {
    colorBase = primaryColor;
    myStlLabel = { fontSize: 14 };
    iptLabelDown = 0;
  }

  const condFSize = myStlLabel.fontSize ?? 0;
  labelFontSize = setLabelSize(condFSize);
  const iptLabelUp = !condLabelUp && labelFontSize;
  const iptFontSize = iptLabelUp || iptLabelDown;
  const valueColor = sttValue ? 'gray' : 'lightgray';
  const focusColor = sttFocus ? colorBase : valueColor;

  // #region :: info + STYLEs *********
  const {
    container,
    labelView,
    phTxtColor = 'gray',
    stlLabel = {
      color: colorBase,
      fontSize: labelFontSize,
      position: 'absolute',
    },
    ipt = {
      paddingLeft: 0,
      height: !condLines && 30,
      borderBottomWidth: 2,
      borderColor: focusColor,
      outline: 'none',
      backgroundColor: '#fff',
      color: 'black',
      fontSize: iptFontSize,
      fontStyle: sttLabelUp && !sttValue && 'italic',
    },
  } = infoStl;
  // #endregion *********
  const condPhTxtColor = !sttLabelUp ? stlLabel.color : phTxtColor;

  return {
    container,
    labelView,
    stlLabel,
    condLabelUp,
    ipt,
    condPhTxtColor,
    sttLabelUp,
  };
}
