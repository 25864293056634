// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={21}
      height={11}
      viewBox="0 0 21 11"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect y={0.032} width={21} height={3} rx={1.5} fill="#fff" />
      <Rect y={7.032} width={16} height={3} rx={1.5} fill="#fff" />
    </Svg>
  );
}

export default SvgComponent;
