// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={23}
      height={22}
      viewBox="0 0 23 22"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9.938 0C4.596 0 .25 4.346.25 9.688c0 5.343 4.346 9.689 9.688 9.689 5.343 0 9.689-4.346 9.689-9.689C19.627 4.346 15.28 0 9.938 0Zm0 17.588c-4.356 0-7.9-3.544-7.9-7.9 0-4.355 3.544-7.9 7.9-7.9s7.9 3.544 7.9 7.9-3.544 7.9-7.9 7.9Z"
        fill="#000"
      />
      <Path
        d="m21.988 20.473-5.127-5.127a.894.894 0 1 0-1.265 1.265l5.127 5.127a.891.891 0 0 0 1.265 0 .894.894 0 0 0 0-1.265Z"
        fill="#000"
      />
    </Svg>
  );
}

export default SvgComponent;
