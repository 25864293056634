// ---------- import Packs
import React, { useRef } from 'react';

// ---------- import Internals
import { Navigation } from '../Components/Navigation';
import { Banner } from '../Components/Banner';
import { Section1 } from '../Components/Section1';
import { Section2 } from '../Components/Section2';
import { Section3 } from '../Components/Section3';
import { Section4 } from '../Components/Section4';
import { Footer } from '../Components/Footer';
import { Dimensions, ScrollView, Text, View } from 'react-native';

// ---------- set Local Types
type Tprops = { info?: {} };
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A0_View: Tcomp = () => {
  const { height } = Dimensions.get('window');
  const textContainerRef = useRef(null);

  const fnScroll = (num: number) => {
    textContainerRef.current.scrollTo({ x: 0, y: num, animated: true });
  };

  // ---------- set Return
  return (
    <>
      <ScrollView style={{ height: height - 1 }} ref={textContainerRef}>
        <Navigation info={{ fnScroll }} />
        <Banner />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Footer />
      </ScrollView>
    </>
  );
};
