// ---------- import Packs
import { ImageStyle, TextStyle, ViewStyle } from 'react-native';

// ---------- import Internals
import { useStl } from '#tempUseMorfos';

// --------------------------
// ---------- set MAIN Styles
// --------------------------
export const container: ViewStyle = {
  flex: 1,
  ...useStl.pad(30, 80),
};
export const stlImg: ImageStyle = {
  ...useStl.imgFull,
};
export const stlImgContainer: ViewStyle = {
  ...useStl.thumbnail,
};
export const stlMgTop30: ViewStyle = {
  marginTop: 30,
};

// ----------------------------------
// ---------- set LISTs Styles
// ----------------------------------
export const stlLabel = [
  {
    paddingRight: 10,
    width: 200,
    minWidth: 105,
    maxHeight: 40,
    overflow: 'hidden',
  },
];
export const stlWrapList = {
  ...useStl.flexWrap,
  flexDirection: 'row',
};
export const padList = { ...useStl.pad(30, 100) };
export const stlItem = {
  ...useStl.cardItemList,
  ...useStl.flexRow,
  margin: 5,
};

export const stlDetail = [useStl.flex4];
export const stlEmail = [useStl.flex4, { color: '#666666' }];
export const stlDiv = [{ width: 1, height: 14, marginHorizontal: 10 }];
// --- Account Status
export const stlBadge = [
  {
    color: '#ff2603',
    fontWeight: '600',
    backgroundColor: 'rgba(253,227,209,.4)',
    paddingRight: 6,
    paddingLeft: 22,
    borderRadius: 5,
    marginLeft: 10,
    borderColor: '#f17241',
    borderWidth: 1,
    position: 'relative',
  },
];
export const stlInactive = [{ color: '#f4a338', fontWeight: '600' }];
export const stlActive = [
  {
    color: '#a7b58c',
    fontWeight: '600',
    backgroundColor: 'rgba(255,236,213,.4)',
    paddingHorizontal: 6,
    borderRadius: 5,
    marginLeft: 10,
    borderColor: '#d5e2c1',
    borderWidth: 1,
  },
];
// --- Text Status
export const stlConfirm = [useStl.flex4, { color: 'green' }];
export const stlWaiting = [useStl.flex4, { color: '#f4a338' }];
export const stlReality = [useStl.flex4, { color: '#fd5935' }];
export const stlRow = [useStl.flexRow, { marginRight: 10 }]; // mudar nome
export const stlMsg1 = [{ color: '#ff0036' }];
export const stlMsg2 = [{ color: '#666' }];

// ----------------------------------
// ---------- set FORM AND CAD Styles
// ----------------------------------
export const stlBtn1 = {
  ...useStl.btn,
  ...useStl.btnMedium,
  ...useStl.btnPrimary,
  ...useStl.mgB20,
};
export const stlBtn2 = { ...useStl.btn, ...useStl.btnMedium, ...useStl.mgB20 };
export const stlBtn3 = {
  ...useStl.btn,
  ...useStl.btnXSmall,
  ...useStl.btnPrimary,
  ...useStl.mgB20,
};
export const stlTxtWhite = { color: '#fff' };
export const title: TextStyle = {
  fontSize: 14,
};
export const stlDualFields = {
  flexDirection: 'row',
  alignItems: 'end',
  height: 55,
};
export const stlCenter = { ...useStl.flexCenter };
export const stlBtnBox = { ...stlCenter, marginTop: 40 };
export const stlFlex1 = { flex: 1 };
export const stlFldRight = { marginRight: 10, flex: 1 };

// ----------------------------------
// ------------------ set CARD Styles
// ----------------------------------
export const stlSimpleCard = {
  ...useStl.card,
};

export const stlColSmall = { flex: 1, minWidth: 360, paddingHorizontal: 30 };
export const stlColLarge = { flex: 6, paddingHorizontal: 30 };
export const stlWrapColLarge = { ...stlWrapList, flex: 6 };
