// ---------- import Packs
import React from 'react';

// ---------- import Internals
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import { Icon } from '#icons';
import { setData, useData } from '#ctData';
import { MsgForm } from './MsgForm';
import { primaryColor } from '#stl';

// ----------- set Local Types
type Tinfo = {
  path: string;
};

type Tprops = { infoData: Tinfo };
type TComp = React.FC<Tprops>;

export const FormStatus: TComp = ({ infoData, children }) => {
  // ---------- set Params
  const { path } = infoData;

  // ---------- set Data
  const currStatus = useData(path);
  // const fxToggl = () => setToCTD();

  // ---------- set Params
  // const { label, field, path } = infoData;
  // const { checkColor, iconColor, labelStyle } = infoStl;

  // ---------- set Hooks
  // const [sttToggl, setToggl] = React.useState(false);
  // React.useEffect(fxToggl, [sttToggl]);

  // ---------- set Functions
  const objStatus = {
    init: '',
    edit: '',
    loader: <ActivityIndicator color={primaryColor} />,
    msgOk: <MsgForm>Sua mensagem foi enviada!</MsgForm>,
    msgError: <MsgForm>Houve uma falha no envio.</MsgForm>,
    msgIptsNotFilled: (
      <MsgForm>Preenchar Todos os campos para enviar sua mensagem.</MsgForm>
    ),
  };
  const condMsg = objStatus[currStatus] ?? currStatus;

  return (
    <>
      {condMsg}
      <View>{children}</View>
    </>
  );
};
