// ---------- Use Snippet...

// ---------- import Packs
import { TscRoute } from '@morfos/routes';

// ---------- import Internals
import { A0 } from '.';

const route: TscRoute = {
  path: 'landingPage',
  routeCode: 'A0',
  component: A0,
};

export default {
  route,

  modal: { condShow: false, currWork: 'mrkumo' },
  form: { iptsChanges: {} },
  layout: { 1: { y: 0 } },
  list: {
    condList: false,

    itemsList: [''],
    itemsInfo: {},

    msgError: '',
    showError: false,
  },
};
