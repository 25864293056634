// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { Rect, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={11}
      height={10}
      viewBox="0 0 11 10"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect
        width={1.537}
        height={10.266}
        rx={0.768}
        transform="scale(.91756 1.07615) rotate(45 5.141 12.862)"
        fill="#fff"
      />
      <Rect
        width={1.537}
        height={5.898}
        rx={0.768}
        transform="scale(.91756 1.07615) rotate(-45 5.315 1.823)"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
