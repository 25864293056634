// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M6.983.734A.75.75 0 1 1 8.017 1.82L2.264 7.277H17.25a.75.75 0 0 1 0 1.5H2.288l5.73 5.457a.75.75 0 1 1-1.035 1.086L.44 9.087a1.498 1.498 0 0 1 .013-2.134l6.53-6.22Z"
        {...props}
      />
    </Svg>
  );
}

export default SvgComponent;
