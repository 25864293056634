// ---------- import Packs
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ImageBackground,
  StyleSheet,
} from 'react-native';

// ---------- import Internals
import { goTo } from '@morfos/routes';
import src from '#imgs/faixa-azul.jpg';
import { useStl, useResize } from '#tempUseMorfos';

const btnGoContact = () => goTo('contact');

export const Section4 = () => {
  const { resizeTxt, resizeImg, resizeView } = useResize();

  // Responsive Style

  const background = resizeImg({
    mob: {
      width: '100%',
      minHeight: 200,
      paddingTop: 10,
      paddingBottom: 26,
    },
    desk: {
      width: '100%',
      minHeight: 400,
      maxHeight: 540,
      paddingVertical: 80,
    },
  });

  const viewTitle = resizeView({
    mob: {
      marginTop: 10,
      textAlign: 'center',
    },
    desk: {
      marginTop: 20,
      textAlign: 'center',
    },
  });

  const title = resizeTxt({
    mob: {
      color: '#fff',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 24,
    },
    desk: {
      color: '#fff',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 36,
    },
  });

  const viewSubTitle = resizeView({
    mob: {
      textAlign: 'center',
    },
    desk: {
      textAlign: 'center',
    },
  });

  const subTitle = resizeTxt({
    mob: {
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '100',
      fontSize: 12,
      color: '#fff',
    },
    desk: {
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '100',
      fontSize: 14,
      color: '#fff',
    },
  });

  const viewParagraph = resizeView({
    mob: {
      textAlign: 'center',
      width: '60%',
      alignSelf: 'center',
      margin: 20,
    },
    desk: {
      textAlign: 'center',
      width: '40%',
      alignSelf: 'center',
      margin: 50,
    },
  });

  const paragraph = resizeTxt({
    mob: {
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      color: '#fff',
    },
    desk: {
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      color: '#fff',
    },
  });

  const button = resizeView({
    mob: {
      alignSelf: 'center',
      backgroundColor: '#244743',
      borderRadius: 4,
      textAlign: 'center',
      width: 120,
    },
    desk: {
      alignSelf: 'center',
      backgroundColor: '#244743',
      borderRadius: 4,
      textAlign: 'center',
      width: 200,
    },
  });

  const txtButton = resizeTxt({
    mob: {
      // fontFamily: 'Open Sans',
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: 30,
      color: '#fff',
    },
    desk: {
      // fontFamily: 'Open Sans',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 50,
      color: '#fff',
    },
  });

  // End Responsive Style

  return (
    <ImageBackground source={src} style={background}>
      <View style={viewTitle}>
        <Text style={title}>Interessado nestes Serviços?</Text>
      </View>
      <View style={viewSubTitle}>
        <Text style={subTitle}>Entre em contato Conosco</Text>
      </View>
      <View style={viewParagraph}>
        <Text style={paragraph}>
          Gostaríamos de esclarecer todas as suas dúvidas quanto a estes
          serviços e mostrar quais são as nossas etapas de trabalho. Conheça
          passo a passo como chegamos a boas ideias que podem alavacar o seu
          aplicativo ou website.
        </Text>
      </View>
      <TouchableOpacity style={button} onPress={btnGoContact}>
        <Text style={txtButton}>Formulário de Contato</Text>
      </TouchableOpacity>
    </ImageBackground>
  );
};
