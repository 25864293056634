// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={27}
      height={26}
      viewBox="0 0 27 26"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#a)">
        <Path
          d="M20.25 19.57a3.18 3.18 0 1 0 3.22 3.17 3.19 3.19 0 0 0-3.22-3.17Zm.72 3.87a1 1 0 1 1 .29-.7 1 1 0 0 1-.29.75v-.05Zm5.78-19.29a1.25 1.25 0 0 0-.81-.41L6.62 3.49l-.43-1.3A3.27 3.27 0 0 0 3.13.01h-2a1.1 1.1 0 1 0 0 2.19h2a1.06 1.06 0 0 1 1 .7L7.9 14.09l-.23.5a3.31 3.31 0 0 0 3 4.54h11.7a1.1 1.1 0 1 0 0-2.19H10.63a1 1 0 0 1-.84-.48 1.09 1.09 0 0 1-.1-1l.1-.24 12.42-1.27a3.83 3.83 0 0 0 3.3-2.88l1.44-5.94a1 1 0 0 0-.2-.98Zm-3.39 6.42a1.53 1.53 0 0 1-1.39 1.19L9.81 13 7.35 5.67l17.15.25-1.14 4.65Zm-14.57 9a3.18 3.18 0 1 0 3.22 3.17 3.12 3.12 0 0 0-.94-2.25 3.19 3.19 0 0 0-2.28-.92Zm.72 3.87a1 1 0 0 1-.72.29 1 1 0 1 1 1-1 1 1 0 0 1-.28.76v-.05Z"
          {...props}
        />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path {...props} d="M0 0h27v25.92H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
