import { setData, TctData } from '#ctData';

export const setDataSuccess = msg => {
  // ----------- set Data
  const condMsg = msg === 'success';
  const dataAfterAsync = condMsg
    ? { path: 'pub.A1.form.currStatus', val: 'msgOk' }
    : { path: `pub.A1.form.currStatus`, val: 'msgIptsNotFilled' };

  // ----------- change Data
  setData(dataAfterAsync);
};
