import React, { useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
  TextInput,
} from 'react-native';
import { FormStatus } from '#forms/FormStatus';
import { IptTxt } from '#forms/IptTxt';
import { setData, useData } from '#ctData';
import { infoStl } from '#forms/IptTxt/ipTheme';
import { useResize, useStl } from '#tempUseMorfos';
import { callFbFunction } from '../actions/callFbFunction';
import ReCAPTCHA from 'react-google-recaptcha';
import { stlDualFields, stlFlex1, stlFldRight } from '../../../theme';

export const Form = ({ children }: any) => {
  const { resizeTxt, resizeView, resizeImg } = useResize();

  // Responsive Style
  const viewBox = resizeView({
    mob: { marginTop: 30 },
    desk: { marginTop: 60, width: 500 },
  });

  // End Responsive Style

  // Recaptcha Keys
  // https://www.google.com/recaptcha/admin/site/512078294/settings
  const [sttRecap, setRecap] = React.useState(false);
  const getTxt = (txt: string, condMask: boolean, field: string) =>
    setData({ path: `A1.form.iptsChanges.${field}`, val: txt });

  const btnSend = () => callFbFunction();
  const onChange = () => setRecap(!sttRecap);
  // function ColocarFuncao() {
  //   return () => {};
  // }

  return (
    <View style={{ ...useStl.flexCenter }}>
      <View style={viewBox}>
        <IptTxt
          getData={getTxt}
          infoData={{
            field: 'name',
            editData: '',
            label: 'Nome',
            pHolder: 'Escreva...',
            infoStl,
          }}
        />

        <View style={stlDualFields}>
          <View style={stlFldRight}>
            <IptTxt
              getData={getTxt}
              infoData={{
                field: 'email',
                editData: '',
                label: 'E-mail',
                pHolder: 'Escreva...',
                infoStl,
              }}
            />
          </View>
          <View style={stlFlex1}>
            <IptTxt
              getData={getTxt}
              infoData={{
                field: 'phone',
                editData: '',
                maskType: 'phone',
                label: 'Telefone',
                pHolder: 'Escreva...',
                infoStl,
              }}
            />
          </View>
        </View>

        <View style={styles.msgBox}>
          <IptTxt
            getData={getTxt}
            infoData={{
              field: 'message',
              editData: '',
              label: 'Mensagem',
              pHolder: 'Como podemos ajudá-lo?',
              lines: 10,
            }}
          />
        </View>

        <ReCAPTCHA sitekey={process.env.RECAPTCHA_SITE} onChange={onChange} />
        <FormStatus infoData={{ path: 'pub.A1.form.currStatus' }}>
          {sttRecap ? (
            <TouchableOpacity style={styles.stlBtnEnable} onPress={btnSend}>
              <Text style={styles.stlTxtEnable}>Enviar</Text>
            </TouchableOpacity>
          ) : (
            <View style={styles.stlBtnDisable}>
              <Text style={styles.stlTxtDisable}>Enviar</Text>
            </View>
          )}
        </FormStatus>
        {/* <TouchableOpacity style={styles.btn} onPress={ColocarFuncao()}>
          <Text style={styles.btnTxt}>Enviar</Text>
        </TouchableOpacity> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  msgBox: {
    borderStyle: 'solid',
    borderColor: '#CCC',
    borderWidth: 1,
    marginTop: 30,
    marginBottom: 30,
  },

  stlBtnEnable: {
    ...useStl.btn,
    ...useStl.btnMedium,
    ...useStl.btnPrimary,
  },
  stlTxtEnable: { color: '#fff' },

  stlBtnDisable: {
    ...useStl.btn,
    ...useStl.btnMedium,
    backgroundColor: '#f3f3f3',
    opacity: 0.4,
  },
  stlTxtDisable: { color: '#999' },

  btn: {
    height: 50,
    margin: 20,
    padding: 35,
    background: '#2cb6a7',
    fontWeight: 'bold',
    borderRadius: 4,
  },

  btnTxt: {
    color: '#FFF',
    fontSize: 14,
  },
});
