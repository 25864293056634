// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 20 26"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M20.5 10.818C20.5 18.454 10.75 25 10.75 25S1 18.454 1 10.818a9.853 9.853 0 0 1 2.856-6.942A9.716 9.716 0 0 1 10.75 1a9.716 9.716 0 0 1 6.894 2.876 9.853 9.853 0 0 1 2.856 6.942Zm-6.5 0c0 1.808-1.455 3.273-3.25 3.273S7.5 12.626 7.5 10.818c0-1.807 1.455-3.273 3.25-3.273S14 9.011 14 10.818Z"
        stroke="#333"
        fill="transparent"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default SvgComponent;
