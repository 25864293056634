// ---------- import Packs
import React from 'react';
import { Text, ImageBackground } from 'react-native';

// ---------- import Internals
import src from '#imgs/faixa-cinza.jpg';
import { useResize } from '#tempUseMorfos';

export const Banner = () => {
  const { resizeTxt, resizeImg } = useResize();

  // Responsive Style

  const stlBackground = resizeImg({
    mob: { width: '100%', height: 200 },
    desk: { width: '100%', height: 450 },
  });

  const title = resizeTxt({
    mob: {
      // // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      color: '#00ceff',
      fontSize: 30,
      lineHeight: 36,
      marginTop: 100,
      fontWeight: '100',
      textAlign: 'center',
    },
    desk: {
      // // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      color: '#00ceff',
      fontSize: 45,
      lineHeight: 54,
      marginTop: 200,
      fontWeight: '100',
      textAlign: 'center',
    },
  });

  const subtitle = resizeTxt({
    mob: {
      color: '#FFF',
      fontSize: 16,
      textAlign: 'center',
      fontWeight: '100',
    },
    desk: {
      color: '#FFF',
      fontSize: 24,
      textAlign: 'center',
      fontWeight: '100',
    },
  });

  // End Responsive Style

  return (
    <ImageBackground source={src} style={stlBackground}>
      <Text style={title}>Projetos de Aplicativos e Websites</Text>
      <Text style={subtitle}>
        Aplicativos e websites responsivos e funcionais
      </Text>
    </ImageBackground>
  );
};
