// ---------- import Packs
import React from 'react';
import { Image, ImageStyle } from 'react-native';

// ---------- set Local Types
export type Tprops = {
  srce: any;
  widthRef: number;
  resize?: 'cover' | 'contain' | 'stretch';
  imgStyle?: ImageStyle | ImageStyle[];
};
export type Tcomp = React.FC<Tprops>;

// ---------- set Render Component
export const AutoScale: Tcomp = ({
  srce,
  widthRef,
  resize = 'cover',
  imgStyle,
}) => {
  const [desiredHeight, setDesiredHeight] = React.useState(0);

  Image.getSize(srce, (width, height) => {
    setDesiredHeight((widthRef / width) * height);
  });

  return (
    <Image
      source={srce}
      resizeMode={resize}
      style={{
        width: widthRef,
        height: desiredHeight,
        ...imgStyle,
      }}
    />
  );
};
