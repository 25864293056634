// ---------- import Packs
import React, { useRef } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';

// ---------- import Internals
import { Form } from '../Components/Form';
import { Banner } from '../Components/Banner';
import { Navigation } from '../../A0_LandingPage/Components/Navigation';

// ---------- set Local Types
type Tprops = { info?: {} };
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const A1_View: Tcomp = () => {
  const { height } = Dimensions.get('window');
  const textContainerRef = useRef(null);
  const fnScroll = (num: number) => {
    textContainerRef.current.scrollTo({ x: 0, y: num, animated: true });
  };

  // ---------- set Return
  return (
    <>
      <Navigation info={{ fnScroll }} />
      <Content>
        <Banner />
        <Form />
      </Content>
    </>
  );
};

const Content = ({ children }: any) => {
  return <View style={{ marginTop: 60 }}>{children}</View>;
};
