import {
  ccdateMask,
  CNPJMask,
  commaMask,
  credcardMask,
  currencyMask,
  ezLog,
  hourMask,
  numIntMask,
  phoneMask,
} from '#utils';
import { Tmask, TsttValue } from './types';

export const maskSelector = (maskTytpe: Tmask, sttValue: TsttValue) => {
  let condMask = sttValue;

  if (maskTytpe === 'currency') {
    const isNumber = typeof sttValue == 'number';
    if (isNumber) {
      condMask = `R$ ${currencyMask(Number(sttValue).toFixed(2), false)}`;
    } else {
      const roundNumber = Number(currencyMask(sttValue, true)).toFixed(2);
      condMask = `R$ ${String(roundNumber).replace('.', ',')}`;
    }
  }
  if (maskTytpe === 'hour') condMask = hourMask(sttValue);
  if (maskTytpe === 'phone') condMask = phoneMask(sttValue).mask;
  if (maskTytpe === 'cnpj') condMask = CNPJMask(sttValue).mask;
  if (maskTytpe === 'comma') condMask = commaMask(sttValue).mask;
  if (maskTytpe === 'numInt') condMask = numIntMask(sttValue, 3).mask;
  if (maskTytpe === 'credcard') condMask = credcardMask(sttValue).mask;
  if (maskTytpe === 'cvv') condMask = numIntMask(sttValue, 3).mask;
  if (maskTytpe === 'ccdate') condMask = numIntMask(sttValue, 2).mask;

  ezLog({ condMask });
  return condMask;
};
