// ----------- import Packs
import React from 'react';
import { Text, View } from 'react-native';

// ----------- import Internals

// ----------- set Default Component
export default () => (
  <View>
    <Text>{'Tela 404'}</Text>
  </View>
);
