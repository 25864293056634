// ----------- import Internals
import { asyncFn1 } from './asyncFn1';
import { setDataSuccess } from './setDataSuccess';
import { setDataBeforeAsync } from './setDataBeforeAsync';
import { setError } from './setError';
import { getCtData } from '#ctData';

// ----------- set Local Types
// type Tprops = {};
// Recaptcha Keys
// https://www.google.com/recaptcha/admin/site/512078294/settings

// ----------- set Main Function
export const callFbFunction = async () => {
  const formData = getCtData(ct => ct?.A1?.form?.iptsChanges) ?? {};
  const countFields = Object.keys(formData).length;
  const condSendMail = countFields === 4;

  setDataBeforeAsync();

  if (condSendMail) {
    await asyncFn1(formData).catch(setError);
    setDataSuccess('success');
  } else {
    setDataSuccess('error');
  }
};
