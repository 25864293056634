import React from 'react';
import { ItemList } from './ItemList';
import { arrLinks } from './Navigation';

export const List = ({ info }) => {
  const { fnScroll } = info;
  return arrLinks.map((item, idx) => {
    return <ItemList key={idx} info={{ item, fnScroll }} />;
  });
};
