// ---------- import Packs
import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

// ---------- import Internals
import Hover from './hover';
import { useStl, useResize } from '#tempUseMorfos';

// ---------- import Images
import kumo from '#imgs/mrkumo2.png';
import bikelete from '#imgs/bikelete1.png';
import emed from '#imgs/emed.png';
import zeduk from '#imgs/zeduk1.png';
import noisecall from '#imgs/noisecall1.png';
import { setData } from '#ctData';
import { WorkViewer } from './WorkViewer';

export const Section2 = () => {
  const { resizeTxt, resizeImg, resizeView } = useResize();

  // Responsive Style
  const container = resizeView({
    mob: {
      width: '100%',

      ...useStl.flexCenter,
      backgroundColor: '#F5F4F2',
      padding: 10,
      marginTop: 10,
    },
    desk: {
      flex: 1,

      ...useStl.flexCenter,
      backgroundColor: '#F5F4F2',
      paddingVertical: 40,
      marginTop: 50,
    },
  });

  const imagesBox = resizeView({
    mob: {
      ...useStl.flexCenter,
      flexWrap: 'wrap',
    },
    desk: {
      ...useStl.flexRow,
      flexWrap: 'wrap',
      width: '68%',
      marginTop: 10,
    },
  });

  const title = resizeTxt({
    mob: {
      color: '#888',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 24,
      textAlign: 'center',
    },
    desk: {
      color: '#888',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 36,
      textAlign: 'center',
    },
  });

  const imgView = resizeView({
    mob: {
      margin: 10,
    },
    desk: {
      margin: 10,
    },
  });

  const image = resizeView({
    mob: {
      width: 0,
      height: 0,
    },
    desk: {
      width: 333,
      height: 266,
    },
  });

  // End Responsive Style

  const fnGetLayout = e =>
    setData({ path: 'pub.A0.layout.3', val: e.nativeEvent.layout });
  // ---------- set Return
  return (
    <View style={container} onLayout={fnGetLayout}>
      <Text style={title}>Alguns de Nossos Trabalhos</Text>
      <WorkViewer />
      <View style={imagesBox}>
        <Hover source={kumo} title={'Mr. Kumo'} />
        <Hover source={bikelete} title={'Bikelete'} />
        <Hover source={emed} title={'Emed'} />
        <Hover source={zeduk} title={'Zeduk'} />
        <Hover source={noisecall} title={'Noisecall'} />
      </View>
    </View>
  );
};
