// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg
      width={512}
      height={512}
      viewBox="-96 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill="red"
      {...props}
    >
      <Path d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" />
    </Svg>
  );
}

export default SvgComponent;
