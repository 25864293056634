import A0 from './A0_LandingPage/centralData';
import A1 from './A1_Contact/centralData';
import X_404 from './X_404/centralData';

export const routes = {
  A0,
  A1,

  X_404,
};
