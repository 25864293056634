import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

export const Banner = () => {
  // ---------- set Return
  return (
    <View style={styles.container}>
      <Text style={styles.title}>COMO PODEMOS AJUDÁ-LO?</Text>
      <Text style={styles.subtitle}>
        Gostaríamos de Esclarecer suas Dúvidas.
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#2bb6a7',
    textAlign: 'center',
    paddingTop: 120,
    paddingBottom: 80,
  },

  title: {
    // fontFamily: 'Noto Serif',
    fontStyle: 'italic',
    fontWeight: '100',
    fontSize: 30,
    color: '#FFF',
  },

  subtitle: {
    // fontFamily: 'Noto Serif',
    fontStyle: 'normal',
    fontWeight: '100',
    fontSize: 14,
    color: '#FFF',
  },
});
