import React from 'react';

export const setChanges = (
  getData: Function,
  field: string,
  condMask: boolean,
  condValue?: string,
) => {
  // ---------- set Effects
  const fxValue = () => {
    const condEdit = condValue ?? '';
    setValue(condEdit);
  };

  // ---------- set Hooks

  // ---------- set Hooks
  const [sttLabelUp, setLabelUp] = React.useState(false);
  const [sttFocus, setFocus] = React.useState(false);
  const [sttValue, setValue] = React.useState('');
  React.useEffect(fxValue, [condValue]);

  // ---------- set Changes
  const changeValue = (val: string) => {
    setValue(val);
    getData(val, condMask, field);
  };
  const changeBlur = () => {
    setLabelUp(false);
    setFocus(false);
  };
  const changeFocus = () => {
    setLabelUp(true);
    setFocus(true);
  };

  // ---------- set Return
  const vars = { sttLabelUp, sttFocus, sttValue, setValue };
  const sets = { changeValue, changeBlur, changeFocus };
  return { vars, sets };
};
