// ----------- import Packs
import React from 'react';

// ----------- import Internals
import { Connect } from '#ctData';
import { ConnectedRouter } from './config/routes/ConnectedRouter';

// ----------- set Component
export default () => {
  return (
    <Connect>
      <ConnectedRouter />
    </Connect>
  );
};
