import { setData, useData } from '#ctData';
import React from 'react';
import {
  Image,
  Modal,
  Pressable,
  ScrollView,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { works } from './worksData';
import { AutoScale } from '../../../../Components/Simple/AutoScale';
import { ezLog } from '#utils';

export const WorkViewer = () => {
  const scWidth = useWindowDimensions().width - 20;
  const condShow = useData(ct => ct.pub.A0.modal.condShow);
  const currWork = useData(ct => ct.pub.A0.modal.currWork);

  // --------- Imgs
  const currData = works[currWork];

  const img = currData?.img;
  const img1 = currData?.img1;
  const img2 = currData?.img2;
  const img3 = currData?.img3;

  const btnCloseModal = () =>
    setData({
      path: `pub.A0.modal.condShow`,
      val: false,
    });

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={condShow}
      onRequestClose={() => {}}
    >
      <Pressable
        onPress={btnCloseModal}
        style={{
          backgroundColor: 'rgba(0,0,0,0.3)',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            padding: 20,
            width: scWidth - 150,
            height: '90%',
          }}
        >
          <ScrollView>
            <Text style={{ fontSize: 32, marginBottom: 10 }}>
              {currData.title}
            </Text>
            <Text style={{ marginBottom: 20 }}>{currData.content}</Text>
            <AutoScale srce={img} widthRef={scWidth - 200} />
            <AutoScale srce={img1} widthRef={scWidth - 200} />
            <AutoScale srce={img2} widthRef={scWidth - 200} />
            <AutoScale srce={img3} widthRef={scWidth - 200} />
          </ScrollView>
        </View>
      </Pressable>
    </Modal>
  );
};
