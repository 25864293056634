import { TuserInfo } from '#dbTypes/TuserInfo';

// ----------- set Project Data Object
export const projectData: TprjDt = {
  sideRight: { open: false },
  authUser: { loading: false },
};
type TprjDt = {
  sideRight: { open: boolean; component?: any };
  authUser: {
    userInfo?: TuserInfo;
    userId?: string;
    loading: boolean;
  };
  // navControl?: { bigSc?: React.ReactNode };
};
