import React from 'react';
import { Image, Text, View } from 'react-native';
import { useStl, useResize } from '#tempUseMorfos';

//******Images******/
import arrow from '#imgs/arrow.png';
import { setData } from '#ctData';
import { Icon } from '#icons';

export const Section3 = () => {
  const { resizeTxt, resizeImg, resizeView } = useResize();

  // Responsive Style
  const container = resizeView({
    mob: {
      alignSelf: 'center',
      ...useStl.flexCenter,
      ...useStl.flexBetween,
      backgroundColor: '#FFF',
      width: 1100,
      padding: 50,
    },
    desk: {
      alignSelf: 'center',
      ...useStl.flexCenter,
      ...useStl.flexBetween,
      backgroundColor: '#FFF',
      width: 1100,
      padding: 50,
    },
  });

  const viewTitle = resizeView({
    mob: {
      ...useStl.flexCenter,
    },
    desk: {
      ...useStl.flexCenter,
    },
  });

  const title = resizeTxt({
    mob: {
      color: '#888',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 36,
      lineHeight: 28,
    },
    desk: {
      color: '#888',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 36,
      lineHeight: 28,
    },
  });

  const viewSubTitle = resizeView({
    mob: {},
    desk: {},
  });

  const subTitle = resizeTxt({
    mob: {
      color: '#888',
      fontSize: 16,
      fontWeight: '100',
    },
    desk: {
      color: '#888',
      fontSize: 16,
      fontWeight: '100',
    },
  });

  const itensBox = resizeView({
    mob: {
      ...useStl.flexCenter,
      width: '70%',
    },
    desk: {
      ...useStl.flexCenter,
      ...useStl.flexRow,
      width: '70%',
    },
  });

  const itemContainer = resizeView({
    mob: {
      width: 500,
      padding: 20,
    },
    desk: {
      width: 500,
      padding: 50,
    },
  });

  const viewItemImg = resizeView({
    mob: {
      paddingRight: 15,
    },
    desk: {
      paddingRight: 15,
    },
  });
  const itemImg = resizeImg({
    mob: {
      width: 25,
      height: 25,
    },
    desk: {
      width: 25,
      height: 25,
    },
  });

  const viewItemTitle = resizeView({
    mob: {
      ...useStl.flexRow,
      marginLeft: -40,
    },
    desk: {
      ...useStl.flexRow,
      marginLeft: -40,
    },
  });

  const itemTitle = resizeTxt({
    mob: {
      fontSize: 18,
      color: '#333',
      fontWeight: '500',
    },
    desk: {
      fontSize: 18,
      color: '#333',
      fontWeight: '500',
    },
  });

  const viewItemDescription = resizeView({
    mob: {},
    desk: {},
  });

  const itemDescription = resizeTxt({
    mob: {
      color: '#888',
      fontWeight: '400',
      // // fontFamily: 'Open Sans',
      // lineHeight: 20,
    },
    desk: {
      color: '#888',
      fontWeight: '400',
      // fontFamily: 'Open Sans',
      lineHeight: 20,
    },
  });

  // End Responsive Style

  const fnGetLayout = e =>
    setData({ path: 'pub.A0.layout.4', val: e.nativeEvent.layout });
  return (
    <>
      <View style={container} onLayout={fnGetLayout}>
        <View style={viewTitle}>
          <Text style={title}>Nossos Serviços</Text>
        </View>
        <View style={viewSubTitle}>
          <Text style={subTitle}>As melhores soluções para o seu negócio</Text>
        </View>

        {/* ------Itens */}
        <View style={itensBox}>
          <View style={itemContainer}>
            <View style={viewItemTitle}>
              <View style={viewItemImg}>
                <Icon.DeskScreen width={30} height={30} fill={'#444'} />
              </View>
              <Text style={itemTitle}>Webdesign</Text>
            </View>
            <View style={viewItemDescription}>
              <Text style={itemDescription}>
                Nossos projetos visam mais do que um design atraente, pois
                sabemos o quanto a experiência do usuário pode afetar sua
                decisão de compra. Navegação confusa, links quebrados, falta de
                informação podem gerar uma experiência ruim a um prospectivo
                cliente. Buscamos entregar websites que possam gerar uma boa
                experiência aos usuários o que certamente deverá causar um
                impacto positivo da sua marca na mente deles.
              </Text>
            </View>
          </View>
          <View style={itemContainer}>
            <View style={viewItemTitle}>
              <View style={viewItemImg}>
                <Icon.Search width={24} height={24} fill={'#444'} />
              </View>
              <Text style={itemTitle}>Posicionamento Mecanismos de Busca</Text>
            </View>
            <View style={viewItemDescription}>
              <Text style={itemDescription}>
                Conquistar uma boa visibilidade nos mecanismos de busca depende
                de muitos fatores. Sites relevantes para buscadores e usuários,
                conteúdo de qualidade e boa arquitetura da informação são
                fatores essenciais para alcançar bons resultados. Além de
                desenvolvermos websites bem estruturados, trabalhamos na escolha
                de palavras chave que contribuem para alcançar uma boa
                visibilidade.
              </Text>
            </View>
          </View>
        </View>
        <View style={itensBox}>
          <View style={itemContainer}>
            <View style={viewItemTitle}>
              <View style={viewItemImg}>
                <Icon.AndroidIco width={30} height={30} fill={'#444'} />
              </View>
              <Text style={itemTitle}>Aplicativos Nativos</Text>
            </View>
            <View style={viewItemDescription}>
              <Text style={itemDescription}>
                Nossa equipe especializada está preparada para elaborar os
                protótipos, testar a usabilidade, criar o layout, desenvolver o
                aplicativo e lançar nas principais lojas de aplicativos. Nós
                cuidamos da parte de tecnologia do começo ao fim e você mantém o
                foco no seu negócio.
              </Text>
            </View>
          </View>
          <View style={itemContainer}>
            <View style={viewItemTitle}>
              <View style={viewItemImg}>
                <Icon.Mobile width={24} height={24} fill={'#444'} />
              </View>
              <Text style={itemTitle}>Dispositivos Móveis</Text>
            </View>
            <View style={viewItemDescription}>
              <Text style={itemDescription}>
                Cada dia que passa a o número de aparelhos celulares com acesso
                a internet aumenta. Ter um website que se adapte em telas
                menores sem a necessidade de ficar aumentando ou diminuindo a
                visualização do celular é essencial. Todos os projetos que
                trabalhamos são responsivos, isto significa que mais pessoas
                podem visualizar o seu website.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
