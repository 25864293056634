import { useData } from '#ctData';
import { goTo } from '@morfos/routes';
import React, { useState } from 'react';
import { Text, View } from 'react-native';

export const ItemList = ({ info }) => {
  const route = useData(ct => ct.dev.selectedRoute);
  const { item, fnScroll } = info;
  const position = useData(ct => ct.pub.A0.layout[item.id]);
  const [sttColor, setColor] = useState('#FFF');
  const condSc = route === 'contact';

  return (
    <View
      onMouseEnter={() => {
        setColor('#01d2cf');
      }}
      onMouseLeave={() => {
        setColor('#FFF');
      }}
      onClick={() => {
        condSc ? goTo('landingPage') : fnScroll(position.y);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Text
        style={{
          color: sttColor,
          marginRight: 20,
          width: 60,
          textAlign: 'center',
        }}
      >
        {item.label}
      </Text>
    </View>
  );
};
