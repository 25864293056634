// ---------- import Packs
import React from 'react';
import { View, Text, ViewStyle, TextStyle } from 'react-native';

// ---------- import Internals

// ---------- set Local Types
type Tprops = { info?: {} };
type Tcomp = React.FC<Tprops>;

// ---------- set Screen Component
export const MsgForm: Tcomp = ({ children }) => {
  // ---------- set Return
  return (
    <View style={container}>
      <Text style={title}>{children}</Text>
    </View>
  );
};

// ---------- set Styles
const container: ViewStyle = {
  flex: 1,
};
const title: TextStyle = {
  fontSize: 14,
  color: 'green',
};
