import { setData, TctData } from '#ctData';

export const setDataBeforeAsync = async () => {
  // ----------- set Data
  const dataBeforeAsync = (ct: TctData) => {
    const change1 = { path: 'pub.A1.form.currStatus', val: 'loader' };
    return [change1];
  };

  // ----------- change Data
  setData(dataBeforeAsync);
};
