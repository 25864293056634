// ----------- import Packs
import { initializeApp } from 'firebase/app';

// ----------- project Packs
import { getFirestore } from 'firebase/firestore';

// ----------- placeHolder Packs
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// ---------- chave do cliente
const fbConfig = {
  apiKey: 'AIzaSyBtdWWPi8a4zRAEkIr6qEvQKOU3Hkfp-NY',
  authDomain: 'start-morfos.firebaseapp.com',
  databaseURL: 'https://start-morfos.firebaseio.com',
  projectId: 'start-morfos',
  storageBucket: 'start-morfos.appspot.com',
  messagingSenderId: '147367265071',
  appId: '1:147367265071:web:4aa0fe7bdfa0093e',
};

// ---------- chave vazia
// const fbConfig = {
//   apiKey: 'xxx',
//   authDomain: 'xxx',
//   projectId: 'xxx',
//   storageBucket: 'xxx',
//   messagingSenderId: 'xxx',
//   appId: 'xxx',
// };

// ----------- start Firebase
export const fbInit = initializeApp(fbConfig);

// ----------- project Packs
export const firestoreInit = getFirestore(fbInit);

// ----------- placeHolder Packs
export const auth = getAuth(fbInit);
export const storage = getStorage(fbInit);
export const functions = getFunctions(fbInit);
// export const messaging = getMessaging(fbInit)

// ---------- set Type to FirebaseData
export const fbType = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snap: any) => snap.data() as T,
});
