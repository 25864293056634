// ----------- import Packs
import { httpsCallable } from '@firebase/functions';

// ----------- import Internals
import { functions } from '#firebase';

export const asyncFn1 = async formData => {
  // ---------- set Async Call
  const sendMailOverHTTP = httpsCallable(functions, 'sendMailOverHTTP');
  const objRes = await sendMailOverHTTP(formData).catch(err => {
    return err;
  });
  // ----------- set Return
  return objRes;
};
