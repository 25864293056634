import React, { useRef, useState } from 'react';
import {
  Image,
  View,
  StyleSheet,
  Animated,
  Text,
  Modal,
  TouchableOpacity,
  ScrollView,
} from 'react-native';

//*****Import Internals*******/
import { useStl, useResize } from '#tempUseMorfos';
import bikelete from '../../../../config/assets/images/bikelete.png';
import bikelete1 from '../../../../config/assets/images/bikelete1.png';
import { setData } from '#ctData';

const Hover = props => {
  //*****Set Hooks*******/
  const varHeight = useRef(new Animated.Value(0)).current;
  const [sttOnHover, setOnHover] = useState(false);

  const stlBadge = {
    width: 333,
    height: varHeight,
    opacity: 0.9,
    backgroundColor: '#222',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
  };

  // Responsive Style
  const { resizeTxt, resizeImg, resizeView } = useResize();

  const stlModal = resizeView({
    mob: {},
    desk: { width: 600, height: 600, backgroundColor: 'red' },
  });

  //****Set Modal******/
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleModal = () => {
    setIsModalVisible(() => !isModalVisible);
  };

  // ---------- set Functions
  const fnEnter = () => {
    Animated.timing(varHeight, {
      toValue: 60,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setOnHover(true);
  };
  const fnLeave = () => {
    Animated.timing(varHeight, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
    setOnHover(false);
  };

  const selector = props.title
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace('.', '')
    .replace(' ', '')
    .toLowerCase();

  const btnViewWork = () =>
    setData([
      { path: 'pub.A0.modal.condShow', val: true },
      { path: 'pub.A0.modal.currWork', val: selector },
    ]);

  return (
    <TouchableOpacity
      onMouseEnter={fnEnter}
      onMouseLeave={fnLeave}
      onPress={btnViewWork}
    >
      <View style={styles.viewAnimated}>
        <View style={styles.imgView}>
          {sttOnHover ? (
            <Image
              source={props.source}
              style={[styles.img, { opacity: 0.8 }]}
            />
          ) : (
            <Image source={props.source} style={styles.img} />
          )}
        </View>
        <Animated.View style={stlBadge}>
          <Text style={styles.itemTxt} onPress={handleModal}>
            {props.title}
          </Text>
        </Animated.View>
        <Modal
          animationType="slide"
          transparent={true}
          visible={isModalVisible}
          onRequestClose={() => {
            handleModal();
          }}
        >
          <ScrollView>
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <View style={{ ...useStl.flexRow }}>
                  <Text style={styles.modalText}>{props.title}</Text>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => handleModal()}
                  >
                    <Text style={styles.textStyle}>X</Text>
                  </TouchableOpacity>
                </View>

                <View>
                  <Text>Projeto de Identidade Visual e Website</Text>
                </View>
                <View>
                  <Image
                    source={bikelete}
                    style={{ width: 790, height: 630, marginBottom: 10 }}
                  />
                  <Image
                    source={bikelete1}
                    style={{ width: 790, height: 630 }}
                  />
                </View>
              </View>
            </View>
          </ScrollView>
        </Modal>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imgView: {
    width: 333,
    height: 266,
    backgroundColor: '#000',
  },

  img: {
    width: 333,
    height: 266,
  },

  itemTxt: {
    color: '#FFF',
    textAlign: 'center',
    marginTop: 20,
  },

  viewAnimated: {
    width: 333,
    height: 266,
    overflow: 'hidden',
    margin: 10,
  },

  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },

  modalView: {
    margin: 20,
    padding: 35,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    ...useStl.flexTopCenter,
    backgroundColor: '#fff',
    borderRadius: 20,
    textAlign: 'center',
    position: 'relative',
    width: '90%',
  },

  button: {
    width: 50,
    height: 50,
    borderRadius: 20,
    alignContent: 'center',
  },

  textStyle: {
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 30,
    paddingLeft: 500,
  },

  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});

export default Hover;
