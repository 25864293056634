import { primaryColor } from '#stl';
import { TextStyle } from 'react-native';
import { TinfoStl } from './types';

export const infoStl: TinfoStl = {
  stlLabel: { fontSize: 14 },
  colorBase: primaryColor,
  container: {
    width: '100%',
    marginBottom: 10,
    height: 40,
    justifyContent: 'end',
  },
};
