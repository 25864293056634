import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

//------ Import Internals
import { useStl, useResize } from '#tempUseMorfos';
import { setData } from '#ctData';
import { goTo } from '@morfos/routes';

//------ Set Functions

const ColocarFuncao = () => goTo('contact');
export const Section1 = () => {
  const { resizeTxt, resizeView, resizeImg } = useResize();

  // Responsive Style
  const stlBox = resizeView({
    mob: {
      width: '95%',
      marginTop: 10,
      backgroundColor: '#FFF',
      alignSelf: 'center',
      ...useStl.flexBetween,
    },
    desk: {
      width: '70%',
      marginTop: 40,
      backgroundColor: '#FFF',
      alignSelf: 'center',
      ...useStl.flexBetween,
      alignContent: 'center',
    },
  });

  const paragraphBox = resizeView({
    mob: {
      width: '85%',
      alignItems: 'center',
    },
    desk: {
      width: '70%',
      alignItems: 'center',
    },
  });

  const header1 = resizeTxt({
    mob: {
      color: '#999',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 20,
      textAlign: 'center',
      padding: 10,
    },
    desk: {
      color: '#999',
      // fontFamily: 'Noto Serif',
      fontStyle: 'italic',
      fontWeight: '100',
      fontSize: 36,
      textAlign: 'center',
      padding: 20,
    },
  });

  const paragraph = resizeTxt({
    mob: {
      color: '#888',
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 12,
      textAlign: 'center',
      padding: 5,
    },
    desk: {
      color: '#888',
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      textAlign: 'center',
      padding: 10,
    },
  });

  const paragraphItem = resizeTxt({
    mob: {
      color: '#888',
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 12,
      textAlign: 'center',
    },
    desk: {
      color: '#888',
      // fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 16,
      textAlign: 'center',
    },
  });

  // End Responsive Style

  const fnGetLayout = e =>
    setData({ path: 'pub.A0.layout.2', val: e.nativeEvent.layout });

  return (
    <>
      <View style={stlBox} onLayout={fnGetLayout}>
        <View>
          <Text style={header1}>
            O que é necessário para se ter um bom aplicativo ou website?
          </Text>
        </View>
        <View style={paragraphBox}>
          <View>
            <Text style={paragraph}>
              Um bom aplicativo ou website deve principalmente comunicar
              corretamente os valores da marca da sua empresa, transmitir
              credibilidade, ter uma linguagem adequada ao público alvo, ser
              acessível a qualquer usuário e por fim ter uma boa visibilidade
              nos mecanismos de busca.
            </Text>
            <Text style={paragraph}>
              Quanto a credibilidade a Universidade de Standford realizou um
              estudo que apontou 10 diretrizes para a construção da
              credibilidade em projetos de aplicativos e websites. Destacamos 3
              delas:
            </Text>
          </View>
          <View>
            <Text style={paragraphItem}>
              1 Deve mostrar que existe uma instituição organizada por trás do
              projeto.
            </Text>
          </View>
          <View>
            <Text style={paragraphItem}>
              2 Deve realçar o que há de melhor nos produtos ou serviços de sua
              empresa.
            </Text>
          </View>
          <View>
            <Text style={paragraphItem}>
              3 O design deve parecer profissional ou apropriado para sua
              finalidade.
            </Text>
          </View>
          <Text style={paragraph}>
            Nós temos a experiência em desenvolvimento e design para criar
            aplicativos de celular e websites dentro destes padrões de
            qualidade. Uma boa presença na web poderá ser traduzida em
            resultados para sua empresa. Entre em contato conosco para que
            possamos discutir seu projeto.
          </Text>
          <TouchableOpacity style={styles.button} onPress={ColocarFuncao}>
            <Text style={styles.txtButton}>Solicitar um Projeto</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

//------Styles

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#2cb6a7',
    width: 200,
    height: 50,
    borderRadius: 4,
    alignContent: 'center',
    paddingTop: 17,
    paddingRight: 35,
    paddingLeft: 35,
    margin: 20,
  },

  txtButton: {
    color: '#FFF',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 13,
    textAlign: 'center',
  },
});
