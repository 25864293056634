import X_404 from '.';
import { TscRoute } from '@morfos/routes';

const route: TscRoute = {
  path: 'x_404',
  routeCode: 'X_404',
  component: X_404,
};

export default {
  route,

  content: {
    pt: { title: '', msg: '' },
  },

  form: { iptsChanges: {} },
};
