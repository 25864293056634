// ---------- import Packs
import { TscRoute } from '@morfos/routes';

// ---------- import Internals
import { A1 } from '.';

const route: TscRoute = {
  path: 'contact',
  routeCode: 'A1',
  component: A1,
};

export default {
  route,

  content: {
    pt: { title: '', msg: '' },
  },

  form: { iptsChanges: {} },

  list: {
    condList: false,

    itemsList: [''],
    itemsInfo: {},

    msgError: '',
    showError: false,
  },
};
