import { setConnect } from '@morfos/central-data';
import { routes as pub } from '../../screens/pub';
import { devData } from './devData';
import { projectData } from './projectData';
import { callSideR } from './callSideR';

// ----------- set Central Data Object
const initCtData = { dev: devData, projectData, pub };
export type TctData = typeof initCtData;

// ----------- set Central Data Connect
export const { Connect, setData, useData, getCtData } = setConnect(initCtData);
export const setSideR = callSideR;
