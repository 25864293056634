import { Troutes } from '@morfos/routes';

// ----------- set Dev Data Object
export const devData: TdevDt = {};
type TdevDt = {
  errorMsg?: string;
  devLog?: number;
  routes?: {
    readAll: boolean;
    routesInfo: Troutes;
    selected: boolean;
  };
};
