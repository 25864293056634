// ---------- import Packs
import React, { useState } from 'react';
import { Image, TouchableOpacity, View, ViewStyle } from 'react-native';

// ---------- import Internals
import src from '#imgs/morfos-logo.png';
import { goTo } from '@morfos/routes';
import { List } from './List';
import { useResize, useStl } from '#tempUseMorfos';
import { Icon } from '#icons';

export const arrLinks = [
  { label: 'Home', id: '1' },
  {
    label: 'Sobre',
    id: '2',
  },
  {
    label: 'Portifolio',
    id: '3',
  },
  {
    label: 'Serviços',
    id: '4',
  },
  { label: 'Contato', id: '5' },
];

export const Navigation = ({ info }) => {
  const { resizeTxt, resizeView, resizeImg } = useResize();
  const [sttShowMenu, setShowMenu] = useState(false);

  // Responsive Menu
  const stlShow = {
    position: 'absolute',
    top: 60,
    width: '100%',
    ...useStl.flexCenter,
    backgroundColor: '#000',
    paddingBottom: 10,
  };
  const stlHidden = { display: 'none' };
  const condShow = sttShowMenu ? stlShow : stlHidden;

  const btnTogglMenu = () => setShowMenu(!sttShowMenu);

  const navMenu = {
    mob: { ...condShow },
    desk: {
      ...useStl.flexRow,
      ...useStl.flexBetween,
    },
  };

  const topLimit = {
    mob: {
      ...useStl.flexRow,
      ...useStl.flexBetween,
      width: '100%',
      height: 60,
    },
    desk: {
      ...useStl.flexRow,
      ...useStl.flexBetween,
      width: '100%',
      height: 60,
      padding: 20,
      paddingRight: 100,
      paddingLeft: 100,
    },
  };

  const stlLogo = {
    mob: {
      width: '50%',
      height: 40,
      resizeMode: 'contain',
      marginTop: 5,
      marginBottom: 5,
    },
    desk: { width: 165, height: 40 },
  };

  const stlCondBtnIcon = {
    mob: { paddingRight: 40 },
    desk: { display: 'none' },
  };

  // End Responsive Menu

  const { fnScroll } = info;

  // ---------- set Return
  return (
    <>
      <View style={navbar}>
        <View style={resizeView(topLimit)}>
          <Image source={src} style={resizeImg(stlLogo)} />

          <TouchableOpacity
            style={resizeView(stlCondBtnIcon)}
            onPress={btnTogglMenu}
          >
            <Icon.Menu width={20} height={20} fill="#000" />
          </TouchableOpacity>

          <View style={resizeView(navMenu)}>
            <List info={{ fnScroll }} />
          </View>
        </View>
      </View>
    </>
  );
};

// ---------- Styles

const navbar: ViewStyle = {
  ...useStl.flexCenter,
  backgroundColor: '#000',
  position: 'fixed',
  width: '100%',
  top: 0,
  left: 0,
  zIndex: 900,
};

const btnTxt: ViewStyle = {
  marginHorizontal: 10,
};
