// CRIAR NO FIGMA E COLAR COMPONENTE BASEADO NO SVG TRANSFORMADO PELO SITE:
// https://bit.ly/SVG-RN-6

// ---------- PASTE BELOW
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

function SvgComponent(props: SvgProps) {
  return (
    <Svg viewBox="0 0 20 20" fill="#AAAA" {...props}>
      <Path
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 12.182a2.182 2.182 0 100-4.364 2.182 2.182 0 000 4.364z"
      />
      <Path
        stroke="#AAA"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.382 12.182a1.2 1.2 0 00.24 1.323l.043.044a1.455 1.455 0 11-2.058 2.058l-.043-.043a1.2 1.2 0 00-1.324-.24 1.2 1.2 0 00-.727 1.098v.123a1.455 1.455 0 11-2.91 0v-.065a1.2 1.2 0 00-.785-1.098 1.2 1.2 0 00-1.323.24l-.044.043a1.455 1.455 0 11-2.058-2.058l.043-.043a1.2 1.2 0 00.24-1.324 1.2 1.2 0 00-1.098-.727h-.123a1.454 1.454 0 110-2.91h.065a1.2 1.2 0 001.098-.785 1.2 1.2 0 00-.24-1.323l-.043-.044a1.455 1.455 0 112.058-2.058l.043.043a1.2 1.2 0 001.324.24h.058a1.2 1.2 0 00.727-1.098v-.123a1.455 1.455 0 012.91 0v.065a1.2 1.2 0 00.727 1.098 1.2 1.2 0 001.323-.24l.044-.043a1.455 1.455 0 112.058 2.058l-.043.043a1.2 1.2 0 00-.24 1.324v.058a1.2 1.2 0 001.098.727h.123a1.455 1.455 0 010 2.91h-.065a1.2 1.2 0 00-1.098.727z"
      />
    </Svg>
  );
}

export default SvgComponent;
